import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Divider, Typography, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { StoreApi } from '../../api/storeApi';
import { FulfillmentCenterAPI } from '../../api/fulfillmentCenterApi';
import * as notificationActions from '../../actions/notificationAction';
import BackButton from '../../components/Shared/BackButton';
import FulfillmentCenterList from '../../components/FulfillmentCenter/FulfillmentCenterList';
import AuthenticationForm from '../../components/FulfillmentCenter/AuthenticationForm';
import { LogisticsProvider, useLogistics } from '../../components/Helpers/logisticsContext';

const styles = {
  paper: {
    paddingRight: 0,
    width: '100%',
    border: '1px solid lightgrey'
  },
  actionBtn: {
    marginLeft: '0.75rem',
    borderColor: 'lightgrey'
  }
}

const StoreShowContainer = (props) => {
  const {
    selectedCenter, 
    setSelectedCenter, 
    applyOnOrderStatus, 
    setApplyOnOrderStatus, 
    customOrderStatus, 
    setCustomOrderStatus, 
    isShelfless, 
    setIsShelfless, 
    dreamUsername, 
    setDreamUsername, 
    dreamPassword, 
    setDreamPassword, 
    showCredentials, 
    setShowCredentials,
    showAuthenticationForm,
    setShowAuthenticationForm,
    authenticating,
    setAuthenticating,
    fulfillmentCenters,
    setFulfillmentCenters,
    selectedFulfillmentCenter,
    setSelectedFulfillmentCenter,
    store,
    setStore
  } = useLogistics(); 

  const fetchStore = async () => {
    try {
      const response = await StoreApi.getStore(props.storeId);
      setStore(response.data);
      setFulfillmentCenters(response.data.fulfillmentCenters || []);
    } catch (error) {
      props.notifyActions.setErrorNotification({ message: error.message });
    }
  };

  const fetchAllFulfillmentCenters = async () => {
    try {
      const response = await FulfillmentCenterAPI.getFulfillmentCenters({ page: -1 });
      setFulfillmentCenters(response.data.data);
    } catch (error) {
      props.notifyActions.setErrorNotification({ message: error.message });
    }
  };

  useEffect(() => {
    fetchStore();
    fetchAllFulfillmentCenters();
  }, [props.storeId]);

  const handleAddFulfillmentCenter = async (centerId) => {
    try {
      await StoreApi.addFulfillmentCenterToStore(props.storeId, centerId);
      fetchStore();
      fetchAllFulfillmentCenters();
      props.notifyActions.setSuccessNotification({ message: 'Fulfillment Center added successfully!' });
    } catch (error) {
      props.notifyActions.setErrorNotification({ message: error.response?.data?.message || 'Failed to add Fulfillment Center' });
    }
  };

  const handleRemoveFulfillmentCenter = async (centerId) => {
    try {
      await StoreApi.removeFulfillmentCenterFromStore(props.storeId, centerId);
      fetchStore();
      fetchAllFulfillmentCenters();
      props.notifyActions.setSuccessNotification({ message: 'Fulfillment Center removed successfully!' });
    } catch (error) {
      props.notifyActions.setErrorNotification({ message: error.response?.data?.message || 'Failed to remove Fulfillment Center' });
    }
  };

  const handleActivate = (center) => {
    setSelectedCenter(center);
    setIsShelfless(center.system_name === 'shelfless');
    setApplyOnOrderStatus(center.StoreFulfillmentCenters?.applyOnOrderStatus || 'default');
    setShowAuthenticationForm(true);

    if (center.system_name === 'shelfless' && center.StoreFulfillmentCenters.username) {
      setShowCredentials(false);
    } else {
      setShowCredentials(true);
    }
  };

  const handleDeactivate = async (center) => {
    try {
      await StoreApi.authenticateLogistics(props.storeId, center.id, applyOnOrderStatus === 'customized' ? customOrderStatus : applyOnOrderStatus, 'inactive');
      fetchStore();
      fetchAllFulfillmentCenters();
      props.notifyActions.setSuccessNotification({ message: 'Fulfillment Center deactivated successfully!' });
    } catch (error) {
      props.notifyActions.setErrorNotification({ message: error.response?.data?.message || 'Failed to deactivate Fulfillment Center' });
    }
  };

  const handleEdit = (center) => {
    setSelectedCenter(center);
    setIsShelfless(center.system_name === 'shelfless');
    setApplyOnOrderStatus(center.StoreFulfillmentCenters?.applyOnOrderStatus || 'default');
    setShowAuthenticationForm(true);

    if (center.system_name === 'shelfless' && center.StoreFulfillmentCenters.username) {
      setShowCredentials(false);
    } else {
      setShowCredentials(true);
    }
  };

  const handleAuthenticate = async () => {
    if (isShelfless && showCredentials && (!dreamUsername || !dreamPassword)) {
      props.notifyActions.setErrorNotification({ message: 'Username and Password are required for Shelfless.' });
      return;
    }

    setAuthenticating(true);
    try {
      if (isShelfless) {
        await StoreApi.authenticateLogistics(
          props.storeId, 
          selectedCenter.id, 
          applyOnOrderStatus === 'customized' ? customOrderStatus : applyOnOrderStatus, 
          'active',
          showCredentials ? dreamUsername : undefined, 
          showCredentials ? dreamPassword : undefined
        );
      } else {
        await StoreApi.authenticateLogistics(
          props.storeId,
          selectedCenter.id,
          applyOnOrderStatus === 'customized' ? customOrderStatus : applyOnOrderStatus
        );
      }
      props.notifyActions.setSuccessNotification({ message: 'Fulfillment center activated successfully!' });
      setShowAuthenticationForm(false);
      fetchStore();
      fetchAllFulfillmentCenters();
    } catch (error) {
      props.notifyActions.setErrorNotification({ message: error.response?.data?.message || 'Activation failed' });
    }
    setAuthenticating(false);
  };

  const handleRevokeCredentials = () => {
    setShowCredentials(true);
    setDreamUsername('');
    setDreamPassword('');
  };

  return (
    <Grid container spacing={3}>
      <Grid container item={true} xs={12} md={12} lg={12} xl={12} alignItems="center">
        <Grid item xs={12} md={6} lg={6} xl={6}>
          <Typography variant="h3">{store.name}</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={6} xl={6} textAlign="end">
          <BackButton />
          <Button sx={styles.actionBtn} variant="outlined" href={`/admin/stores/${store.id}/edit`}>
            <EditIcon />&nbsp;Edit
          </Button>
        </Grid>
      </Grid>
      <Divider width="100%" sx={{ paddingTop: '1%' }} />
      <FulfillmentCenterList
        onAddFulfillmentCenter={handleAddFulfillmentCenter}
        onRemoveFulfillmentCenter={handleRemoveFulfillmentCenter}
        onActivate={handleActivate}
        onDeactivate={handleDeactivate}
        onEdit={handleEdit}
      />
      <AuthenticationForm onAuthenticate={handleAuthenticate} onRevokeCredentials={handleRevokeCredentials} />
      <Divider width="100%" sx={{ marginTop: '2%', marginBottom: '2%' }} />
    </Grid>
  );
};

const StoreShowContainerWithProvider = (props) => (
  <LogisticsProvider>
    <StoreShowContainer {...props} />
  </LogisticsProvider>
);

const mapStateToProps = (state, ownProps) => {
  const storeId = Number(ownProps.match.params.id);
  return { storeId };
};

const mapDispatchToProps = (dispatch) => {
  return {
    notifyActions: bindActionCreators(notificationActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreShowContainerWithProvider);
